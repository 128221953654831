<template>
    <form id="login_form" @submit.prevent="formController($event,url)">
        <div class="form-group">
            <input 
                type="text" 
                class="form-control form-control-sm" 
                placeholder="Correo" 
                name="email"
                v-on="{ click: clearErrorMsg, focusin: clearErrorMsg }"
            >
            <div class="invalid-feedback email-errors"></div>
        </div>
        <div class="form-group">
            <input 
                type="password" 
                class="form-control form-control-sm" 
                placeholder="Clave" 
                name="password"
                v-on="{ click: clearErrorMsg, focusin: clearErrorMsg }"
            >
            <div class="invalid-feedback password-errors"></div>
        </div>
        <div class="form-group collapse">
            <a href="" @click.prevent="goToRecover" class="linking">¿Olvidaste tu contraseña?</a>
        </div>
        <div class="form-group text-center mt-3">
            <button type="submit" class="btn btn-sm btn-welt-invert">Ingresar</button>
            <button type="button" class="btn btn-sm btn-welt" @click.prevent="goToRegister">Registrarse</button>
        </div>
    </form>
</template>

<script>

    import formController from '../../../../general/js/mixins/formController';

    export default {
        mixins: [ formController ],
        props: {
            url: {
                type: String,
                default: ''
            }
        },
        data() {
            return {
                model: {
                    email: '',
                    password: '',
                }
            }
        },
        methods: {
            goToRegister: function() {
                window.bus.$emit('changeForm',2);
            },
            goToRecover: function() {
                window.bus.$emit('changeForm',3);
            },
        },
    }
</script>

<style>

</style>