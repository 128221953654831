<template>
    <div class="loading" v-if="show">
        <div class="nb-spinner"></div>
    </div>
</template>

<script>
    export default {
        props: {
        },
        data(){
            return {
                show: false
            }
        },
        computed: {
        },
        created() {
            window.bus.$on('loading', function(data){
                this.show = data;
            }.bind(this));
        },
    }
</script>
<style scoped>
    .loading {
        align-items: center;
        background: rgba(0,0,0,0.8);
        bottom: 0; 
        display: flex;
        justify-content: center;
        left: 0;
        position: fixed;
        right: 0;
        top: 0; 
        z-index: 9999;
    }
    .nb-spinner {
        -webkit-animation: 1s spin linear infinite;
        animation: 1s spin linear infinite;
        background: transparent;
        border-radius: 50%;
        border-right: 4px solid transparent;
        border-top: 4px solid #7376da;
        height: 75px;
        margin: 0;
        width: 75px;
    }
 
    @keyframes spin {
        from {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        to {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
</style>