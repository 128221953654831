import swalMessages from './swalMessages';
import formErrors from './formErrors';

export default {
    mixins: [ swalMessages, formErrors ],
    components: {
    },
    props: {
    },
    data() {
        return {
        }
    },
    created() {
    },
    methods: {
        formController: function(event,url,extra_info = null,avoid = null) 
        {
            let fd = new FormData(event.target);
            if(extra_info != null)
                fd.append('extra_info',JSON.stringify(extra_info));
                
            if (avoid != null) {
                avoid.forEach(element => {
                    fd.delete(element);
                });
            }
            window.bus.$emit('loading',true);
            axios.post(url,fd,
                        { headers: {
                            'Content-type': 'application/x-www-form-urlencoded',
                        }
                    }).then(response => {
                window.bus.$emit('loading',false );
                window.bus.$emit('clearForm');
                this.alertMsg( response.data );
                
            }).catch(error => {
                window.bus.$emit('loading',false);
                let obj = error.response.data.errors;
                this.showErrors(event.target,obj);
            });
        },
        clearErrorMsg: function(event)
        {
            let elem = $(event.target);
            if(elem.hasClass('is-invalid'))
            {
                elem.removeClass('is-invalid');
                elem.next().html('');
            }
        },
    },
}