<template>
    <form id="register_form" @submit.prevent="formController($event,url)">
        <div class="form-group">
            <input 
                type="text" 
                class="form-control form-control-sm" 
                placeholder="Nombre completo" 
                name="name"
                v-on="{ click: clearErrorMsg, focusin: clearErrorMsg }"
            >
            <div class="invalid-feedback name-errors"></div>
        </div>
       
        <div class="form-group">
            <input 
                type="text" 
                class="form-control form-control-sm" 
                placeholder="Correo" 
                name="email"
                v-on="{ click: clearErrorMsg, focusin: clearErrorMsg }"
            >
            <div class="invalid-feedback email-errors"></div>
        </div>
        <div class="form-group">
            <input 
                type="text" 
                class="form-control form-control-sm" 
                placeholder="Celular" 
                name="phone"
                v-on="{ click: clearErrorMsg, focusin: clearErrorMsg }"
                @keypress="isNumber($event)"
                maxlength="9"
            >
            <div class="invalid-feedback phone-errors"></div>
        </div>
        <div class="form-group">
            <input 
                type="password" 
                class="form-control form-control-sm" 
                placeholder="Clave" 
                name="password"
                v-on="{ click: clearErrorMsg, focusin: clearErrorMsg }"
            >
            <div class="invalid-feedback password-errors"></div>
        </div>
        <div class="form-group">
            <input 
                type="password" 
                class="form-control form-control-sm" 
                placeholder="Confirmar clave" 
                name="password_confirmation"
                v-on="{ click: clearErrorMsg, focusin: clearErrorMsg }"
            >
            <div class="invalid-feedback password_confirmation-errors"></div>
        </div>
        <div class="form-group">
            <a href="" @click.prevent="goToLogin" class="linking">¿Ya tienes cuenta?</a>
        </div>
        <div class="form-group text-center">
            <button type="submit" class="btn btn-welt">Ingresar</button>
        </div>
    </form>
</template>

<script>

    // import form fr}om '../mixins/form';
    // import filters from '../mixins/filters';
    import formController from '../../../../general/js/mixins/formController';
    import filters from '../../../../general/js/mixins/filters';

    export default {
        mixins: [ formController, filters ],
        props: {
            url: {
                type: String,
                default: ''
            }
        },
        methods: {
            goToLogin: function() {
                window.bus.$emit('changeForm',1);
            },
        },
    }
</script>

<style>

</style>