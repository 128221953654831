<template>
    <li>
        <a href="" @click.prevent="openModal()">
            <i class="far fa-user"></i>
        </a>
    </li>
</template>

<script>
export default {
    methods: {
        openModal: function() {
            window.bus.$emit('openLoginRegisterModal');
        }
    },
}
</script>

<style>

</style>