
// import  moment from 'moment';
// const moment = require('moment');
export default {
    filters: {
        dateFormat: function (value,format) {
            return value ? moment(String(value)).format(format) : '';
        },
    },
    data() {
        return {
        }
    },
    components: {
    },
    mounted() {
       
    },
    methods: {
        isNumber: function(evt,extras = []) {
            let keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
            extras.forEach(element => {
                keysAllowed.push(element)
            });
            const keyPressed = evt.key;
            if (!keysAllowed.includes(keyPressed)) {
                evt.preventDefault()
            }
        }
    },
}
